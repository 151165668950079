function OglomeruloOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелся острый гломерулонефрит, экссудативно-пролиферативная форма, на что указывают макро- и микро- скопические изменения в почках;
        результаты гистохимического и иммуногистохимического исследований: - обнаружение фибрина в капсуле Боумена (окраска фибрином), - наличие отложений иммуноглобулинов 
        (IgG, IgA, IgM) и комплемента (C3) на базальной мембране клубочков; а так же лабораторные исследования мочи и крови: - моча (протеинурия (белок), гематурия (эритроциты), 
        цилиндрурия (гранулярные, гиалиновые цилиндры), - кровь (повышение уровней креатинина и мочевины, гипоальбуминемия, повышение С-реактивного белка)), наличие в анамнезе 
        указания на предшествующую стрептококковую инфекцию (ангину), перенесенную 3 недели назад.
        Смерть наступила вследствие острого гломерулонефрита, осложнившегося острой почечной недостаточностью, что подтверждается: 
макро- и микро- скопическими изменениями как в самих почках (отек периренальной клетчатки и капсулы, дряблая консистенция почек, бледный цвет коркового слоя и темно-красный цвет медуллярного слоя 
(«двухцветные почки»), полнокровие с застойными явлениями мозгового слоя, истончение коркового слоя с очагами некроза в нем и микротромбозами в сосудах коркового слоя;
дистрофия эпителия, десквамация клеток, облитерация просвета, образование цилиндров в просвете (гиалиновые, зернистые, пигментные), разрывы базальной мембраны в проксимальных 
канальцах; некроз эпителия, наличие тубулоинтерстициального фиброза в дистальных канальцах; полнокровие и капиллярный стаз, капиллярные микротромбозы, утолщение базальной мембраны 
в клубочках; отек, инфильтрация лейкоцитами, очаги кровоизлияний в интерстиции; острый артериолоспазм, тромбоз мелких сосудов почек), так и в других органах:
в легких (отек, полнокровие, гиалиновые мембраны в альвеолах, интерстициальный отек), в сердце (дистрофия миокарда, венозное полнокровие), головной мозг (отек, набухание, 
точечные кровоизлияния), печень (центролобулярный некроз, жировая дистрофия), наряду со значительным повышением биохимических показателей крови:
креатинин: 1400 мкмоль/л (норма: 44–106 мкмоль/л), мочевина: 60 ммоль/л (норма: 2,5–8,3 ммоль/л), гиперкалиемия (повышение калия в крови): 8,5 ммоль/л (норма: 3,5–5,0 ммоль/л),
повышение уровней азотистых шлаков: остаточный азот: 140 ммоль/л (норма: 14,3–28,6 ммоль/л), мочевая кислота: 1000 мкмоль/л (норма: 150–420 мкмоль/л).
Таким образом, между имевшимся острым гломерулонефритом и наступлением смерти имеется прямая причинная связь.
        Острый гломерулонефрит — это иммуновоспалительное заболевание почек, характеризующееся преимущественным поражением клубочков (гломерул), а также вовлечением канальцев и 
        интерстициальной ткани. Основой патогенеза является образование иммунных комплексов и их отложение в почечных клубочках, что приводит к активации воспалительного процесса, 
        нарушению почечной фильтрации и развитию клинических проявлений. Клинические признаки:
-	Отеки: чаще всего на лице, особенно по утрам.
-	Артериальная гипертензия.
-	Гематурия: моча приобретает вид «мясных помоев».
-	Олигурия: снижение суточного объема мочи.
-	Протеинурия: наличие белка в моче.
        </p>
    )
}
export default OglomeruloOsnVyvody