function HronPieloOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
           При жизни имелся двусторонний хронический пиелонефрит в стадии склерозирования (сморщенные почки, бугристая поверхность, умеренная деформация чашечно-лоханочной системы, 
           атрофия канальцев, лимфоцитарная инфильтрация, фиброз интерстиция, склероз клубочков), на что указывают макро- и микро- скопические изменения в почках; выделение 
           микрофлоры (Klebsiella spp.) из чашечно-лоханочной системы почек и мочевого пузыря при бактериологическом исследовании.
        Смерть наступила вследствие хронического пиелонефрита в стадии склерозирования, осложнившегося хронической почечной недостаточностью в терминальной стадии, что 
        подтверждается: 
макро- и микро- скопическими изменениями как в самих почках (выраженный нефросклероз, облитерация клубочков, атрофия канальцев и массивный фиброз), так и в других органах:
в легких (отек, полнокровие, гиалиновые мембраны в альвеолах, интерстициальный отек), в сердце (дистрофия миокарда, венозное полнокровие), головной мозг (отек, набухание, 
точечные кровоизлияния), печень (центролобулярный некроз, жировая дистрофия), наряду со значительным повышением биохимических показателей крови:
креатинин: 1400 мкмоль/л (норма: 44–106 мкмоль/л), мочевина: 60 ммоль/л (норма: 2,5–8,3 ммоль/л), гиперкалиемия (повышение калия в крови): 8,5 ммоль/л (норма: 3,5–5,0 ммоль/л),
повышение уровней азотистых шлаков: остаточный азот: 140 ммоль/л (норма: 14,3–28,6 ммоль/л), мочевая кислота: 1000 мкмоль/л (норма: 150–420 мкмоль/л).
Таким образом, между имевшимся хроническим пиелонефритом в стадии склерозирования и наступлением смерти имеется прямая причинная связь.
        Хронический пиелонефрит — это хроническое неспецифическое инфекционно-воспалительное заболевание почек, преимущественно поражающее интерстициальную ткань, канальцы, а в 
        дальнейшем сосуды и клубочки. Оно характеризуется волнообразным течением с чередованием периодов обострения и ремиссии, постепенной прогрессирующей утратой функциональной 
        способности почек, рубцово-склеротическими изменениями паренхимы и развитием хронической почечной недостаточности в поздних стадиях. Причинами хронического пиелонефрита 
        чаще всего являются инфекции мочевых путей, нарушение оттока мочи (обструкция, рефлюксы) и общие факторы риска, такие как снижение иммунитета, сахарный диабет, 
        артериальная гипертензия и другие состояния, способствующие поддержанию хронического воспаления.
        </p>
    )
}
export default HronPieloOsnVyvody