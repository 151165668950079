function KronaOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелась болезнь Крона, хроническое рецидивирующее течение с сегментарным поражением терминального отдела подвздошной кишки и участков восходящего отдела 
            толстой кишки (утолщение стенки 
            кишечника, наличие глубоких язвенных поражений, трансмуральное воспаление с формированием фиброза и стриктур, неказеозные гранулемы в стенке кишечника, признаки 
            «ползучего жира» - брыжеечный жир обволакивает пораженные участки кишечника, лимфоидная гиперплазия мезентериальных лимфатических узлов), на что указывают макро- и 
            микро- скопические изменения в этих органах; отрицательные результаты на сальмонеллы, шигеллы, кампилобактер, Clostridium difficile - при бактериологическом 
            исследовании; обнаружение мутаций в гене NOD2/CARD15 (положительный результат в 2 локусах - что подтверждает генетическую предрасположенность) - при 
            молекулярно-генетическом исследовании, а так же данные иммуногистохимического исследования: CD68 - выраженная экспрессия, подтверждающая наличие гранулём из 
            эпителиоидных клеток и макрофагов, TNF-α - высокая экспрессия в гранулёмах и воспалённых участках слизистой, CD3/CD20 - лимфоцитарная инфильтрация с преобладанием 
            CD3+ (Т-клеток).
        Смерть наступила вследствие болезни Крона осложнившейся перфорацией подвздошной кишки в области её терминального отдела (диаметр перфорации 0,3 см) и последующего 
        разлитого фибринозно-гнойного перитонита и септического шока, что подтверждается наличием: выраженных изменений брюшины (помутнения серозных оболочек, тусклости, гиперемии и отека брюшины, 
        множественных наложений фибрина с гнойным экссудатом, локальных абсцессов, в том числе в области перфорации); массивного выпота в брюшной полости (наличие мутного 
        серозно-гнойного экссудата с хлопьями фибрина (общий объем — 700 мл)), обильного фибринозного налета на петлях кишечника, сальнике и париетальной брюшине; выраженной 
        гиперемии и полнокровия сосудов кишечника и брыжейки; очаговых кровоизлияний на серозных оболочках (петехии и экхимозы) в области брыжейки, сальника, печени и селезенки; 
        изменений в кишечнике (отек и инфильтрация стенок кишки воспалительным инфильтратом, эрозии и изъязвления слизистой, выраженное полнокровие подслизистого слоя, 
        микротромбозы мелких сосудов, гнойные затёки в стенке кишки, некротические изменения в зоне перфорации); признаков системного воспалительного ответа и органной 
        недостаточности (печень: дистрофические изменения гепатоцитов, жировая и гидропическая дистрофия, венозный застой; почки: острая гипоксическая дистрофия эпителия канальцев, 
        отек интерстиция, полнокровие сосудов мозгового и коркового вещества; легкие: отек и полнокровие, множественные точечные кровоизлияния, признаки респираторного 
        дистресс-синдрома на фоне септического состояния; селезенка: реактивная гиперплазия лимфоидной ткани, венозное полнокровие, микротромбозы в синусах); признаков 
        септического процесса (наличие гнойных очагов, диссеминированного внутрисосудистого свертывания (ДВС-синдром), множественные кровоизлияния в слизистые оболочки желудка и 
        кишечника, петехии на серозных оболочках внутренних органов, микротромбозы в капиллярах печени, почек, надпочечников); общих признаков воспалительной интоксикации и 
        полиорганной недостаточности, характерных для септического шока (резкое малое наполнение сосудов кровью, дистрофические изменения в миокарде, почках и печени, признаки 
        гипоксического повреждения головного мозга (периваскулярный и перицеллюлярный отек, набухание астроцитов)).
Таким образом, между имевшейся болезнью Крона, хронического рецидивирующего течения с сегментарным поражением терминального отдела подвздошной кишки и участков восходящего отдела 
толстой кишки и наступлением смерти имеется прямая причинная связь.
        Болезнь Крона — это хроническое гранулематозное воспалительное заболевание желудочно-кишечного тракта, характеризующееся сегментарным поражением с трансмуральным 
        (затрагивающим все слои стенки) воспалением. Патология может локализоваться в любом отделе желудочно-кишечного тракта, от ротовой полости до ануса, но чаще всего поражает 
        терминальный отдел тонкой кишки и толстую кишку. Основными клиническими проявлениями являются хроническая диарея, боли в животе, потеря массы тела и системные симптомы 
        воспаления, такие как лихорадка. Заболевание может сопровождаться осложнениями, включая стенозы, свищи, абсцессы, а также внекишечные проявления, такие как поражение кожи, 
        суставов, глаз и печени.
        </p>
    )
}
export default KronaOsnVyvody