function YazvaOsnDiagnoz(){
    return (
        <p id="MsoBodyTextIndent" className="green">
       Хроническая язвенная болезнь желудка, фаза обострения. Язва в области малой кривизны желудка, размерами 2,5 × 1,5 см, с валикообразными краями и 
       плотным фиброзным дном. Аррозия сосуда в дне язвы, состоявшееся массивное желудочное кровотечение от ... (дата). Жидкая кровь и свертки крови в просвете желудка и верхнем 
       отделе тонкой кишки (2000 мл).
Осложнения: геморрагический шок IV ст. с ДВС синдромом, вызванный массивным желудочно-кишечным кровотечением.
        </p>
    )
}
export default YazvaOsnDiagnoz