function UshibRanaMkOpis(){
    return (
        <p id="MsoBodyTextIndent" className="blue">В теменной области справа в 1,2 см от срединной линии и в 170 см от подошвенной поверхности правой стопы имеется имеется рана 
        пятилучевой звездчатой формы, общими 
        размерами 39 х 19мм, с длинами лучей от 6 мм до 11 мм. Края раны неровные, в центральной части края размозжены и осаднены в виде овала шириной до 10 мм и наружным 
        диаметром 25-32 мм. В стенках раны видны вывихнутые луковицы волос. Концы раны закруглены, некоторые - остроугольные с соединительнотканными перемычками. 
        На внутренней поверхности кожного лоскута темно-красное кровоизлияние в кожу. 
        Дном раны является дырчатый перелом правой теменной кости, округлой формы размерами 35х37 мм с относительно ровными скошенными краями. Отмечается воронкообразный скол 
        внутренней костной пластинки. Для дальнейшего лабораторного исследования изъят кожный лоскут с вышеописанной раной и участок костей свода черепа с вышеописанным дырчатым 
        переломом (см. ниже раздел "Данные лабораторных и специальных исследований").
        </p>

        
    )
}
export default UshibRanaMkOpis