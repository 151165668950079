function PnevmoniaInterstOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
             При жизни имелась идиопатическая интерстициальная пневмония хронического течения, на что указывают макро- и микро- скопические изменения в легких (дифузный 
             интерстициальный фиброз лёгких, утолщение альвеолярных перегородок с лимфо-макрофагальной инфильтрацией, "сотовое лёгкое" (на фоне выраженного фиброза), пролиферация 
             пневмоцитов II типа); отрицательные результаты посевов на бактерии, грибы и вирусы; отсутствие патогенных микроорганизмов при ПЦР-диагностике.
        Смерть наступила вследствие идиопатической интерстициальной пневмонии хронического течения, осложнившейся вторичной легочной гипертензией с формированием легочного сердца 
        и последующей легочно-сердечной недостаточностью, что подтверждается макро и микро -скопическим изменениями в легких (макроскопически: увеличены в объеме, уплотнены, 
        поверхность разной плотности за счет чередования участков фиброза и эмфиземы, цвет варьирует от серо-розового до серо-синюшного; в области корней легких — утолщенные 
        сосудистые стволы, застой крови, расширение капилляров; микроскопически: выраженный альвеолярный и интерстициальный фиброз, утолщение межальвеолярных перегородок, 
        гипертрофия гладкомышечных элементов артериол, склероз сосудов, варикозное расширение капилляров; очаги эмфиземы, стазы эритроцитов в сосудах) и сердце (макроскопически:
        увеличено за счет правых отделов - гипертрофия и дилатация правого желудочка, миокард правого желудочка утолщен (более 5 мм), относительная недостаточность трехстворчатого 
        клапана, венозное полнокровие правых отделов; микроскопически: гипертрофия и гиперплазия кардиомиоцитов правого желудочка, разрастание соединительной 
        ткани в стенке правого желудочка (миокардиофиброз), дистрофические изменения кардиомиоцитов (зернистая дистрофия), очаги периваскулярного фиброза), 
        а также в других органах с признаками нарушения газообмена, гипоксии и системных последствий: головной мозг (отек мозга, точечные кровоизлияния в белом веществе и 
        мозжечке, дистрофические изменения нейронов), печень (острая (зернистая и вакуольная) центролобулярная дистрофия, полнокровие центральных вен), почки (острая (вакуольная) 
        тубулярная дистрофия, отек интерстиция). 
Таким образом, между имевшейся идиопатической интерстициальной пневмонией хронического течения и наступлением смерти имеется прямая причинная связь.
            Интерстициальная пневмония — это группа диффузных воспалительных заболеваний лёгких, характеризующихся преимущественным поражением интерстициальной ткани 
            (соединительнотканного каркаса) лёгких, включающей альвеолярные перегородки, сосудистое русло и прилегающие структуры.
Патологический процесс включает: утолщение альвеолярных перегородок за счёт воспалительной инфильтрации (лимфоциты, макрофаги, плазматические клетки); повреждение эпителия 
альвеол; развитие фиброза и утрату нормальной архитектоники лёгочной ткани. Клинически интерстициальная пневмония проявляется прогрессирующей одышкой, кашлем (обычно сухим), 
снижением насыщения крови кислородом и нарушением функции лёгких по рестриктивному типу. Интерстициальная пневмония может быть идиопатической (например, идиопатический лёгочный 
фиброз) или развиваться как осложнение системных заболеваний соединительной ткани, инфекции, воздействия токсинов или радиации.
        </p>
    )
}
export default PnevmoniaInterstOsnVyvody