function AstmaOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
             При жизни имелась бронхиальная астма, аллергическая форма, тяжелого течения, на что указывают макро- и микро- скопические изменения в легких и в сердце (легочное 
             сердце), а также наличие гипоксических изменений головного мозга, печени и почек, повышенный уровень иммуноглобулина E (общий IgE) в крови, эозинофилия в общем 
             анализе крови.
        Смерть наступила вследствие бронхиальной астмы аллергической формы тяжелого течения и развившегося на фоне нее астматического статуса с тотальной обструкцией бронхов 
        слизистыми пробками, острым вздутием легких и последующей асфиксией вследствие обструкции дыхательных путей, что подтверждается наличием: обструкции бронхов всех калибров 
        густым бесцветным секретом, признаков застойных явлений в большом и малом кругах кровообращения (выраженный отек легочной ткани, полнокровие сосудов малого круга 
        кровообращения, множественные 
        кровоизлияния в альвеолярные перегородки, застойное увеличение печени (полнокровие центральных зон долек), полнокровие сосудов мозгового и коркового вещества почек, 
        признаки острой гипоксической дистрофии эпителия канальцев почек, полнокровие и расширение синусов в селезенке); признаков венозного застоя и гипоксии (полнокровие вен и 
        мелких сосудов внутренних органов, множественные точечные кровоизлияния (петехии) на серозных оболочках (эпикард, плевра, брюшина), слизистых оболочках и коже), а также 
        общеасфиктических признаков: жидкой темной крови в сосудах, переполнения правых отделов сердца кровью, выраженного отека мозга, точечных кровоизлияний в конъюнктиве глаз, 
        акроцианоза, кровоизлияний под эпикард, плевру и брюшину, а также слизистых оболочек желудка и кишечника (признак Шарпея).
Таким образом, между имевшейся бронхиальной астмой, аллергической формы, тяжелого течения и наступлением смерти имеется прямая причинная связь.
Бронхиальная астма — это хроническое воспалительное заболевание дыхательных путей, характеризующееся гиперреактивностью бронхов в ответ на различные раздражители, обратимой 
(самопроизвольно или под воздействием терапии) бронхиальной обструкцией и эпизодами удушья. Основу патогенеза составляет хроническое воспаление дыхательных путей, в котором 
участвуют множество клеток, включая тучные клетки, эозинофилы, Т-лимфоциты и макрофаги. Это приводит к структурным изменениям бронхов, гиперсекреции слизи, утолщению базальной 
мембраны, гипертрофии гладкомышечной ткани и инфильтрации стенок бронхов воспалительными клетками. Клинические проявления включают приступы одышки, свистящие хрипы, ощущение 
сжатия в груди и кашель, которые обычно возникают ночью или рано утром и провоцируются аллергенами, инфекциями, физической нагрузкой, стрессом или холодным воздухом. 
        </p>
    )
}
export default AstmaOsnVyvody