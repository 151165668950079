function IshInsultOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> 
        При жизни имелся стенозирующий атеросклероз артерий головного мозга (3-я степень, III стадия, стеноз до 70%), что привело к образованию нестабильной атеросклеротической 
        бляшки и красного обтурирующего тромба длиной 1,5 см в левой средней мозговой артерии с развитием ишемического инсульта в бассейне средней мозговой артерии слева (зона 
        инфаркта в теменно-височной доле левого полушария мозга размерами 6,0 × 5,0 см) от ...(дата), на что указывают макро- и микро- скопические изменения головного мозга 
        и его сосудов.
        Смерть наступила вследствие ишемического инсульта, осложнившегося отеком и дислокацией головного мозга, что подтверждается макро- и микроскопическими изменениями 
        головного мозга (увеличение массы и объема, 
        сглаженность извилин, уплощение базальных структур, отек мозгового вещества с нарушением границы серого и белого вещества, желудочки сжаты, смещение срединных структур, 
        вклинение крючка гиппокампа в ствол мозга, венозное полнокровие сосудов основания, диапедезные кровоизлияния; микроскопически: отек нейропиля, вакуолизация цитоплазмы 
        нейронов, гиперхромия ядер, реактивный астроцитоз, эозинофилия цитоплазмы нейронов, деструкция аксонов, демиелинизация, геморрагии в области моста и ножек мозга 
        (синдром Дюрета)), а также в других органах: легкие (венозное полнокровие, отек, пятна Тардье; микроскопически: интерстициальный и альвеолярный отек, пропитывание плазмой 
        и эритроцитами, фибрин и множественные мелкие кровоизлияния в альвеолах, участки эмфиземы, геморрагическая инфильтрация), сердце (застойное полнокровие правых отделов, 
        гипоксическая дистрофия миокарда, мелкие кровоизлияния; микроскопически: отек кардиомиоцитов, очаги 
        гипоксии), печень (застойное полнокровие, мускатная печень; микроскопически: гидропическая и вакуольная дистрофия), почки (кортикальный застой; микроскопически: дистрофия 
        эпителия канальцев, очаги некроза), селезенка (увеличение размеров, венозное полнокровие, гиперплазия лимфоидной ткани).
Таким образом, между имевшимся ишемическим инсультом и наступлением смерти имеется прямая причинная связь.
        Ишемический инсульт — это острое нарушение мозгового кровообращения, вызванное частичным или полным прекращением кровотока в артериях головного мозга, приводящее к 
        недостатку кислорода и глюкозы в тканях мозга, развитию ишемии, некроза нейронов и нарушению их функций. Основные причины ишемического инсульта включают:
-	Тромбоз: образование тромба в месте атеросклеротической бляшки в церебральных артериях.
-	Эмболия: блокировка мозговых сосудов эмболом, который может происходить из сердца или других сосудов.
-	Системная гипоперфузия: снижение общего кровоснабжения мозга из-за гипотонии или остановки сердца.
Клинически ишемический инсульт характеризуется:
-	Внезапной очаговой неврологической симптоматикой (например, паралич, афазия, потеря чувствительности).
-	Симптомами поражения определённой области мозга, в зависимости от локализации и объема ишемии.
        </p>
    )
}
export default IshInsultOsnVyvody