function TubercOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">При жизни имелся туберкулез легких, фиброзно-кавернозная форма, хроническое течение, фаза обострения (по результатам 
        исследования трупа: двусторонние каверны в верхних долях легких, множественные туберкулезные гранулемы с казеозным некрозом; обширный фиброз легочной ткани; туберкулезный 
        плеврит, спаечный процесс, амилоидоз почек), на что указывают макро- и микро- скопические изменения в этих органах, наличие в ткани легких (при бактериологическом 
        исследовании) микобактерий туберкулеза.  
        Смерть последовала от туберкулеза легких, осложнившегося кровоизлиянием в каверну правого легкого с развитием геморрагического шока, что подтверждается наличием:
- крупной каверны в верхней доле правого легкого с признаками свежего кровоизлияния в её полость, представленного наличием большого количества жидкой крови и сгустков 
(общим объемом 1000 мл);
- выраженных признаков массивной кровопотери, таких как анемия внутренних органов (светлоокрашенная печень, селезенка и почки, а также малое наполнение сосудов жидкой кровью) и
признаки гипоперфузии тканей (субэндокардиальные кровоизлияния в сердце - пятна Минакова, дистрофические изменения в почках и печени).
Таким образом, между имевшимся туберкулезом легких и наступлением смерти имеется прямая причинная связь.
        Туберкулёз — это хроническое инфекционное заболевание, вызываемое микобактериями туберкулёза (Mycobacterium tuberculosis complex), которое характеризуется образованием 
        специфических гранулём (туберкулов) в поражённых тканях и органах, склонностью к прогрессирующему течению, разрушению тканей, а также развитием интоксикации и 
        иммунопатологических реакций. Основным источником инфекции является больной человек или животное, передача возбудителя происходит преимущественно воздушно-капельным путём. 
        Чаще всего поражаются лёгкие, но процесс может затрагивать и другие органы: лимфатические узлы, кости, почки, кожу и мозговые оболочки.
        </p>
    )
}
export default TubercOsnVyvody