function YazvaOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелась хроническая язвенная болезнь желудка в фазе обострения, на что указывают макро- и микро- скопические изменения в желудке (язва в области малой 
            кривизны желудка, размерами 2,5 × 1,5 см, с валикообразными краями и плотным фиброзным дном, аррозия сосуда в дне язвы).
        Смерть наступила вследствие хронической язвенной болезни желудка в фазе обострения осложнившейся массивным желудочно-кишечным кровотечением с геморрагическим 
        шоком, что подтверждается наличием: жидкой крови и свертков крови в просвете желудка и верхнем отделе тонкой кишки (общим объемом 2000 мл), выраженных признаков массивной 
        кровопотери, таких как анемия внутренних органов (светлоокрашенная печень, селезенка и почки, а также малое наполнение сосудов жидкой кровью), 
        признаков ДВС-синдрома (диссеминированного внутрисосудистого свертывания): кровоизлияния на серозных оболочках (эпикард, плевра, брюшина), кровоизлияния в слизистые 
        оболочки желудка, кишечника и признаков гипоперфузии тканей (субэндокардиальные кровоизлияния в сердце - пятна Минакова, дистрофические изменения в 
        почках и печени).
Таким образом, между имевшейся хронической язвенной болезнью желудка в фазе обострения и наступлением смерти имеется прямая причинная связь.
            Язвенная болезнь желудка и двенадцатиперстной кишки — это хроническое рецидивирующее заболевание, характеризующееся образованием язв слизистой оболочки под 
            воздействием агрессивных факторов (кислота, пепсин, Helicobacter pylori) на фоне снижения защитных механизмов; проявляется болью в эпигастрии, изжогой, тошнотой, 
            рвотой, а осложнения включают кровотечения, перфорацию, пенетрацию и малигнизацию, чаще встречается у мужчин трудоспособного возраста.
        </p>
    )
}
export default YazvaOsnVyvody