function PnevmoniaKrupOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
             При жизни имелась крупозная (долевая) пневмония в верхней и нижней долях левого легкого, стадия красного опеченения, фибринозный плеврит с наложениями на висцеральной и 
             париетальной плевре, на что указывают макро- и микро- скопические изменения в легких и плевре, обнаружение в пораженной легочной ткани Streptococcus pneumoniae при 
             бактериологическом исследовании.
        Смерть наступила вследствие крупозной (долевой) пневмонии в верхней и нижней долях левого легкого, осложнившейся острой дыхательной недостаточностью, что подтверждается 
        макро и микро -скопическим изменениями в легких (выраженная интерстициальная инфильтрация лимфоцитами и 
        макрофагами, участки геморрагий, участки уплотнения ткани, инфильтрация нейтрофилами, эмфизематозное вздутие участков легких, ателектазы,
        расширение капилляров и мелких сосудов легких, мелкие геморрагии в межальвеолярных перегородках, отек межальвеолярных перегородок, пропитывание альвеолярных пространств 
        эритроцитами и плазмой), а также в других органах с признаками нарушения газообмена, гипоксии и системных последствий: сердце (зернистая дистрофия миокарда, венозное 
        полнокровие правых отделов), головной мозг (отек мозга, точечные кровоизлияния в белом веществе и мозжечке, дистрофические изменения нейронов), печень (острая (зернистая 
        и вакуольная) центролобулярная дистрофия, полнокровие центральных вен), почки (острая (вакуольная) тубулярная дистрофия, отек интерстиция). 
Таким образом, между имевшейся крупозной (долевой) пневмонией в верхней и нижней долях левого легкого и наступлением смерти имеется прямая причинная связь.
            Крупозная пневмония — это острое инфекционно-воспалительное заболевание, при котором поражается одна или несколько долей легкого с образованием 
            фибринозного экссудата в альвеолах. Заболевание характеризуется стадийным течением (прилив, красное и серое опеченение, разрешение) и сопровождается высокой 
            лихорадкой, кашлем с "ржавой" мокротой, болью в грудной клетке и одышкой. Основной возбудитель — Streptococcus pneumoniae, осложнения включают дыхательную 
            недостаточность, плеврит, абсцессы и сепсис.
        </p>
    )
}
export default PnevmoniaKrupOsnVyvody