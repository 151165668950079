function HolecistitOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелся хронический калькулезный холецистит в стадии обострения, на что указывают макро- и микро- скопические изменения в брюшной полости и желчном пузыре 
            (флегмонозное воспаление стенки желчного пузыря – гистологически, камни смешанного состава в просвете желчного пузыря).
        Смерть наступила вследствие хронического калькулезного холецистита в стадии обострения, осложнившегося 
        деструктивными изменениями стенки желчного пузыря (перфорацией), последующего разлитого фибринозно-гнойного перитонита и септического шока, что подтверждается наличием:
        перфорации стенки желчного пузыря размерами 4 х 3 мм, 
        выраженных изменений брюшины (помутнения серозных оболочек, тусклости, гиперемии и отека брюшины, множественных наложений фибрина с гнойным экссудатом, локальных абсцессов, 
        в том числе в области перфорации); 
        массивного выпота в брюшной полости (наличие мутного серозно-гнойного экссудата с хлопьями фибрина (общий объем — 700 мл)), обильного фибринозного налета на петлях кишечника, 
        сальнике и париетальной брюшине; выраженной 
        гиперемии и полнокровия сосудов кишечника и брыжейки; очаговых кровоизлияний на серозных оболочках (петехии и экхимозы) в области брыжейки, сальника, печени и селезенки; 
        изменений в кишечнике (отек и инфильтрация стенок кишки воспалительным инфильтратом, эрозии и изъязвления слизистой, выраженное полнокровие подслизистого слоя, 
        микротромбозы мелких сосудов, гнойные затёки в стенке кишки, некротические изменения в зоне перфорации); признаков системного воспалительного ответа и органной 
        недостаточности (печень: дистрофические изменения гепатоцитов, жировая и гидропическая дистрофия, венозный застой; почки: острая гипоксическая дистрофия эпителия канальцев, 
        отек интерстиция, полнокровие сосудов мозгового и коркового вещества; легкие: отек и полнокровие, множественные точечные кровоизлияния, признаки респираторного 
        дистресс-синдрома на фоне септического состояния; селезенка: реактивная гиперплазия лимфоидной ткани, венозное полнокровие, микротромбозы в синусах); признаков 
        септического процесса (наличие гнойных очагов, диссеминированного внутрисосудистого свертывания (ДВС-синдром), множественные кровоизлияния в слизистые оболочки желудка и 
        кишечника, петехии на серозных оболочках внутренних органов, микротромбозы в капиллярах печени, почек, надпочечников); общих признаков воспалительной интоксикации и 
        полиорганной недостаточности, характерных для септического шока (резкое малое наполнение сосудов кровью, дистрофические изменения в миокарде, почках и печени, признаки 
        гипоксического повреждения головного мозга (периваскулярный и перицеллюлярный отек, набухание астроцитов)).
Таким образом, между имевшимся хроническим калькулезным холециститом в стадии обострения и наступлением смерти имеется прямая причинная связь.
Желчнокаменная болезнь (холелитиаз) — это заболевание, при котором в желчном пузыре или желчевыводящих путях образуются камни (конкременты). Эти камни могут быть холестериновыми, 
пигментными, смешанными или известковыми (кальциевыми) по составу. Образование камней может быть вызвано различными факторами, включая застой желчи, изменения в её составе, воспаление 
и нарушения обмена веществ. Желчнокаменная болезнь может быть бессимптомной (латентной) или проявляться болями в животе, желтухой, расстройствами пищеварения и осложнениями, 
такими как холецистит или желчная обструкция.
Холецистит — это воспаление желчного пузыря, которое может быть острым или хроническим. Острый холецистит часто сопровождается инфекцией, обычно вызванной бактериями, и 
характеризуется болями в правом подреберье, лихорадкой и возможным желтухой. Хронический холецистит возникает вследствие многократных воспалений и может привести к утолщению 
стенки желчного пузыря, его фиброзу и деформации. Часто наблюдается в сочетании с желчнокаменной болезнью.
        </p>
    )
}
export default HolecistitOsnVyvody