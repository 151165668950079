function PnevmoniaOchagOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
             При жизни имелась очагово-сливная стафилококковая правосторонняя, нижнедолевая, серозно-гнойная пневмония в стадии уплотнения, на что указывают макро- и микро- 
             скопические изменения в легких, обнаружение в пораженной легочной ткани Staphylococcus aureus при бактериологическом исследовании.
        Смерть наступила вследствие очагово-сливной стафилококковой правосторонней, нижнедолевой, серозно-гнойной пневмонии, осложнившейся отеком легких и острой дыхательной 
        недостаточностью, что подтверждается макро и микро -скопическим изменениями в легких (выраженная интерстициальная инфильтрация лимфоцитами и 
        макрофагами, участки геморрагий, участки уплотнения ткани, инфильтрация нейтрофилами, эмфизематозное вздутие участков легких, ателектазы,
        расширение капилляров и мелких сосудов легких, мелкие геморрагии в межальвеолярных перегородках, отек межальвеолярных перегородок, пропитывание альвеолярных пространств 
        эритроцитами и плазмой), а также в других органах с признаками нарушения газообмена, гипоксии и системных последствий: сердце (зернистая дистрофия миокарда, венозное 
        полнокровие правых отделов), головной мозг (отек мозга, точечные кровоизлияния в белом веществе и мозжечке, дистрофические изменения нейронов), печень (острая (зернистая 
        и вакуольная) центролобулярная дистрофия, полнокровие центральных вен), почки (острая (вакуольная) тубулярная дистрофия, отек интерстиция). 
Таким образом, между имевшейся очагово-сливной стафилококковой правосторонней, нижнедолевой, серозно-гнойной пневмонии и наступлением смерти имеется прямая причинная связь.
            Очаговая пневмония — это воспалительное заболевание легочной ткани, характеризующееся развитием ограниченных участков воспаления (очагов) в альвеолах и прилегающих 
            бронхиолах. Возникает вследствие инфицирования бактериями, вирусами, грибами или в результате аспирации, сопровождается инфильтрацией тканей нейтрофилами, отеком и 
            экссудацией. Клинически проявляется кашлем, лихорадкой, одышкой и локальными симптомами воспаления в легких (хрипы, ослабленное дыхание). На рентгенограмме 
            определяется ограниченное затемнение легочной ткани. 
        </p>
    )
}
export default PnevmoniaOchagOsnVyvody