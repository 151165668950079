function IshInsultOsnDiagnoz(){
    return (
        <p id="MsoBodyTextIndent" className="green">  
        Ишемический инсульт в бассейне средней мозговой артерии слева (зона инфаркта в теменно-височной доле левого полушария мозга размерами 6,0 × 5,0 см) от ...(дата).
        Стенозирующий атеросклероз артерий головного мозга (3-я степень, III стадия, стеноз до 70%, нестабильная атеросклеротическая бляшка и красный обтурирующий тромб длиной 
        1,5 см левой средней мозговой артерии).
Осложнения: отек и дислокация головного мозга (смещение срединных структур, вклинение крючка гиппокампа в ствол мозга).
            </p>
    )
}
export default IshInsultOsnDiagnoz