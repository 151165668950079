function AstmaLungs(){
    return (
        <p id="MsoBodyTextIndent" className="green">
        Для бронхиальной астмы характерно: легкие увеличены в объеме, их края закруглены, при пальпации ощущается повышенная воздушность (эмфизема); на разрезе легочная ткань 
        светлая, с участками истончения межальвеолярных перегородок; утолщенные стенки бронхов, наличие слизи, густого секрета или пробок в их просвете; участки спадения легочной 
        ткани, связанные с обструкцией мелких бронхов (ателектазы). В случае смерти во время приступа бронхиальной астмы (астматического статуса) - в бронхах и трахеи много 
        вспененной слизи, обструкция бронхов всех калибров густым бесцветным секретом или густой слизисто-гнойной мокротой, выдавливающейся в виде «червячков», сами легкие - 
        эмфизематозно вздуты, передние отделы лёгких перекрывают почти полностью область сердца, на них могут иметься отпечатки ребер.
        </p>
    )
}
export default AstmaLungs