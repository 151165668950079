function MkbOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> 
        При жизни имелась мочекаменная болезнь (конкременты в чашечно-лоханочной системе правой почки (диаметр до 1,5 см, коралловидной формы), конкремент в верхней трети левого 
        мочеточника (диаметр 0,8 см) с обтурацией, гидронефроз левой почки, атрофия почечной паренхимы, хронический калькулёзный пиелонефрит с интерстициальным фиброзом), 
        на что указывают макро- и микро- скопические изменения в почках и мочеточниках.
        Смерть наступила вследствие мочекаменной болезни, осложнившейся острой почечной недостаточностью и уремией, что подтверждается: 
макро- и микро- скопическими изменениями как в самих почках (отек периренальной клетчатки и капсулы, дряблая консистенция почек, бледный цвет коркового слоя и темно-красный цвет 
медуллярного слоя («двухцветные почки»), полнокровие с застойными явлениями мозгового слоя, истончение коркового слоя с очагами некроза в нем и микротромбозами в сосудах коркового 
слоя; дистрофия эпителия, десквамация клеток, облитерация просвета, образование цилиндров в просвете (гиалиновые, зернистые, пигментные), разрывы базальной мембраны в проксимальных 
канальцах; некроз эпителия, наличие тубулоинтерстициального фиброза в дистальных канальцах; полнокровие и капиллярный стаз, капиллярные микротромбозы, утолщение базальной мембраны 
в клубочках; отек, инфильтрация лейкоцитами, очаги кровоизлияний в интерстиции; острый артериолоспазм, тромбоз мелких сосудов почек), так и в других органах:
в легких (отек, полнокровие, гиалиновые мембраны в альвеолах, интерстициальный отек), в сердце (дистрофия миокарда, венозное полнокровие), головной мозг (отек, набухание, 
точечные кровоизлияния), печень (центролобулярный некроз, жировая дистрофия), аммиачный запах от тканей, наряду со значительным повышением биохимических показателей крови:
креатинин: 1400 мкмоль/л (норма: 44–106 мкмоль/л), мочевина: 60 ммоль/л (норма: 2,5–8,3 ммоль/л), гиперкалиемия (повышение калия в крови): 8,5 ммоль/л (норма: 3,5–5,0 ммоль/л),
повышение уровней азотистых шлаков: остаточный азот: 140 ммоль/л (норма: 14,3–28,6 ммоль/л), мочевая кислота: 1000 мкмоль/л (норма: 150–420 мкмоль/л).
Таким образом, между имевшейся мочекаменной болезнью и наступлением смерти имеется прямая причинная связь.
         Мочекаменная болезнь (нефролитиаз) — это хроническое заболевание, характеризующееся образованием конкрементов (камней) в органах мочевыделительной 
        системы, включая почки, мочеточники, мочевой пузырь и уретру, вследствие нарушения обмена веществ (водно-солевого, белкового) и изменения состава мочи. Клинически 
        заболевание проявляется болями в поясничной области (почечная колика), дизурией, гематурией, а также возможными инфекционно-воспалительными осложнениями, такими как 
        пиелонефрит. Мочекаменная болезнь может приводить к обструкции мочевых путей, гидронефрозу и развитию почечной недостаточности.
        </p>
    )
}
export default MkbOsnVyvody