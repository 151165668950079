function KronaOsnDiagnoz(){
    return (
        <p id="MsoBodyTextIndent" className="green">
        Болезнь Крона, хроническое рецидивирующее течение с сегментарным поражением терминального отдела подвздошной кишки и участков восходящего отдела толстой кишки (утолщение 
        стенки кишечника, наличие глубоких язвенных поражений, трансмуральное воспаление с формированием фиброза и стриктур, неказеозные гранулемы в стенке кишечника, признаки 
        «ползучего жира» - брыжеечный жир обволакивает пораженные участки кишечника, лимфоидная гиперплазия мезентериальных лимфатических узлов).
Осложнения: перфорация терминального отдела подвздошной кишки от ... (дата) (диаметр перфорации 0,3 см), разлитой фибринозно-гнойный перитонит, септический шок.
        </p>
    )
}
export default KronaOsnDiagnoz