function NjakOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелся неспецифический язвенный колит, хроническое течение, стадия обострения (язвенно-некротические изменения слизистой оболочки толстой кишки, 
            грануляционная ткань и псевдополипы, тотальный колит с поражением всех отделов толстой кишки, фиброз и утолщение стенок толстой кишки), на что указывают макро- и микро- 
            скопические изменения в толстой кишке; отрицательные результаты при микробиологическом исследовании на кишечные инфекции (шигеллы, сальмонеллы, клостридии, амёбы, 
            кампилобактер), отрицательные специфические маркеры гранулематозного воспаления (CD68) при иммуногистохимическом исследовании.
        Смерть наступила вследствие хронического течения неспецифического язвенного колита в стадии обострения, осложнившегося перфорацией стенки сигмовидной кишки (диаметр 
        перфорации 0,3 см) и последующего разлитого фибринозно-гнойного перитонита и септического шока, что подтверждается наличием: выраженных изменений брюшины (помутнения 
        серозных оболочек, тусклости, гиперемии и отека брюшины, множественных наложений фибрина с гнойным экссудатом, локальных абсцессов, в том числе в области перфорации); 
        массивного выпота в брюшной полости (наличие мутного серозно-гнойного экссудата с хлопьями фибрина (общий объем — 700 мл)), обильного фибринозного налета на петлях кишечника, 
        сальнике и париетальной брюшине; выраженной 
        гиперемии и полнокровия сосудов кишечника и брыжейки; очаговых кровоизлияний на серозных оболочках (петехии и экхимозы) в области брыжейки, сальника, печени и селезенки; 
        изменений в кишечнике (отек и инфильтрация стенок кишки воспалительным инфильтратом, эрозии и изъязвления слизистой, выраженное полнокровие подслизистого слоя, 
        микротромбозы мелких сосудов, гнойные затёки в стенке кишки, некротические изменения в зоне перфорации); признаков системного воспалительного ответа и органной 
        недостаточности (печень: дистрофические изменения гепатоцитов, жировая и гидропическая дистрофия, венозный застой; почки: острая гипоксическая дистрофия эпителия канальцев, 
        отек интерстиция, полнокровие сосудов мозгового и коркового вещества; легкие: отек и полнокровие, множественные точечные кровоизлияния, признаки респираторного 
        дистресс-синдрома на фоне септического состояния; селезенка: реактивная гиперплазия лимфоидной ткани, венозное полнокровие, микротромбозы в синусах); признаков 
        септического процесса (наличие гнойных очагов, диссеминированного внутрисосудистого свертывания (ДВС-синдром), множественные кровоизлияния в слизистые оболочки желудка и 
        кишечника, петехии на серозных оболочках внутренних органов, микротромбозы в капиллярах печени, почек, надпочечников); общих признаков воспалительной интоксикации и 
        полиорганной недостаточности, характерных для септического шока (резкое малое наполнение сосудов кровью, дистрофические изменения в миокарде, почках и печени, признаки 
        гипоксического повреждения головного мозга (периваскулярный и перицеллюлярный отек, набухание астроцитов)).
Таким образом, между имевшимся неспецифическим язвенным колитом, хронического течения, в стадии обострения и наступлением смерти имеется прямая причинная связь.
        Неспецифический язвенный колит (НЯК) — это хроническое воспалительное заболевание толстой кишки аутоиммунной природы, характеризующееся развитием диффузного воспаления 
        преимущественно в слизистой оболочке и подслизистом слое, образованием язв, эрозий и псевдополипов. Клинически проявляется диареей с примесью крови и слизи, абдоминальной 
        болью, анемией и системными проявлениями. Заболевание имеет волнообразное течение с чередованием обострений и ремиссий. Причина возникновения связана с генетической 
        предрасположенностью, нарушением иммунного ответа и влиянием факторов внешней среды.
        </p>
    )
}
export default NjakOsnVyvody