function PancreatitOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> 
         При жизни имелся острый геморрагический панкреатит с очагами жирового некроза в ткани поджелудочной железы и окружающей жировой клетчатке, на что указывают макро- и 
         микро- скопические изменения в брюшной полости и поджелудочной железе.
        Смерть наступила вследствие острого геморрагического панкреатита, осложнившегося геморрагическим ферментативным перитонитом и полиорганной недостаточностью, что 
        подтверждается: 
массивным мутным геморрагическим выпотом в полости брюшины (объемом 1,5 литра), серозная оболочка брюшины тусклая, отечная, покрыта обильными кровоизлияниями (от петехиальных до 
крупных экхимозов), хлопья фибрина, наложения на стенках кишечника, сальнике, желудке и брюшине, сальник пропитан кровью, с участками некроза, спаян с окружающими органами, стенки 
кишечника гиперемированы с петехиальными и экхимотическими кровоизлияниями, их сосуды полнокровны, в желудке 150 мл кровянистого содержимого (признаки геморрагического 
ферментативного перитонита); гипертрофией и дилятацией левого желудочка сердца, полнокровием 
        внутренних органов (легких, печени, почек, селезенки), застойными явлениями в малом и большом кругах кровообращения (отек легких, гидроторакс, застойное 
        увеличение печени); дистрофическими изменениями в печени (застойная печень с очагами некроза в центролобулярных зонах), тубулярной дистрофией в почках, отеком и 
        полнокровием головного мозга (признаки полиорганной недостаточности).
Таким образом, между имевшимся острым геморрагическим панкреатитом и наступлением смерти имеется прямая причинная связь.
        Панкреатит — это воспалительное заболевание поджелудочной железы, характеризующееся поражением её экзокринной ткани, развитием некротических и дистрофических изменений, 
        отёка и воспалительной инфильтрации, что приводит к нарушению её функций и, в тяжёлых случаях, к системным осложнениям.
Различают две основные формы:
1.	Острый панкреатит — внезапно развивающееся воспаление, сопровождающееся активацией ферментов поджелудочной железы внутри органа, что вызывает аутолиз (самопереваривание), 
некроз тканей и развитие местных или системных осложнений (перитонит, полиорганная недостаточность).
2.	Хронический панкреатит — длительно текущее воспалительное заболевание с прогрессирующим фиброзом, атрофией ацинарной ткани и нарушением ферментативной и гормональной функций 
железы. Причинами панкреатита могут быть:
-	Злоупотребление алкоголем.
-	Желчнокаменная болезнь.
-	Инфекционные заболевания.
-	Токсическое воздействие (медикаменты, токсины).
-	Аутоиммунные процессы.
-	Травмы поджелудочной железы.
Основные клинические проявления включают сильную опоясывающую боль в верхней части живота, тошноту, рвоту, повышение уровня ферментов (амилазы, липазы) и, в тяжёлых случаях, 
признаки системного воспалительного ответа.
        </p>
    )
}
export default PancreatitOsnVyvody