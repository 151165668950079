function HoblOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелась хроническая обструктивная болезнь легких (ХОБЛ): хронический обструктивный бронхит, центролобулярная эмфизема легких, легочная гипертензия, на что 
            указывают макро- и микро- скопические изменения в легких (хронический воспалительный инфильтрат в стенках бронхов (лимфоциты, макрофаги, плазматические клетки), 
            гиперплазия бокаловидных клеток и слизистых желез, утолщение базальной мембраны, метаплазия и десквамация эпителия в бронхах и бронхиолах, угольно-пылевые отложения в 
            макрофагах; увеличение объема легких, повышенная воздушность легочной ткани, наличие булл, деструкция межальвеолярных перегородок) и в сердце (гипертрофия правого 
            желудочка (толщина стенки более 5 мм)).
        Смерть наступила вследствие хронической обструктивной болезни легких (ХОБЛ), с формированием легочного сердца и последующей легочно-сердечной недостаточностью, что 
        подтверждается макро и микро -скопическим изменениями в легких (макроскопически: увеличены в объеме, уплотнены, 
        поверхность разной плотности за счет чередования участков фиброза и эмфиземы, цвет варьирует от серо-розового до серо-синюшного; в области корней легких — утолщенные 
        сосудистые стволы, застой крови, расширение капилляров; микроскопически: выраженный альвеолярный и интерстициальный фиброз, утолщение межальвеолярных перегородок, 
        гипертрофия гладкомышечных элементов артериол, склероз сосудов, варикозное расширение капилляров; очаги эмфиземы, стазы эритроцитов в сосудах) и сердце (макроскопически:
        увеличено за счет правых отделов - гипертрофия и дилатация правого желудочка, миокард правого желудочка утолщен (более 5 мм), относительная недостаточность трехстворчатого 
        клапана, венозное полнокровие правых отделов; микроскопически: гипертрофия и гиперплазия кардиомиоцитов правого желудочка, разрастание соединительной 
        ткани в стенке правого желудочка (миокардиофиброз), дистрофические изменения кардиомиоцитов (зернистая дистрофия), очаги периваскулярного фиброза), 
        а также в других органах с признаками нарушения газообмена, гипоксии и системных последствий: головной мозг (отек мозга, точечные кровоизлияния в белом веществе и 
        мозжечке, дистрофические изменения нейронов), печень (острая (зернистая и вакуольная) центролобулярная дистрофия, полнокровие центральных вен), почки (острая (вакуольная) 
        тубулярная дистрофия, отек интерстиция). 
Таким образом, между имевшейся хронической обструктивной болезнью легких (ХОБЛ) и наступлением смерти имеется прямая причинная связь.
            Хроническая обструктивная болезнь легких (ХОБЛ) — это прогрессирующее воспалительное заболевание дыхательных путей, характеризующееся необратимым или частично 
            обратимым ограничением воздушного потока, связанное с хроническим воспалением, поражающим бронхи, паренхиму легких и сосудистую систему, чаще всего возникающее в 
            результате воздействия вредных факторов, таких как курение, загрязнение воздуха или профессиональные вредности. Основные клинические проявления включают хронический 
            кашель, одышку и продуктивную мокроту, а основным патофизиологическим механизмом является сужение дыхательных путей вследствие воспаления и ремоделирования тканей.
        </p>
    )
}
export default HoblOsnVyvody